.mint-home{
    padding: 100px 0 150px 0;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    margin-top: 140px;
}

.mint-home-content{
    position: relative;
    z-index: 2;
}

#gradient-canvas{
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
}