.hero{
    min-height: 100vh;
}

.hero-legend{
    font-size: 1.4em;
    font-weight: 500;
    font-style: italic;
    background-image: url(../images/gradient-side.png);
    background-position: top left;
    background-repeat: no-repeat;
    background-size: 2px 100%;
    padding-left: 30px;
}

@media only screen and (max-width: 760px){
    .hero-legend{
        font-size: 1em;
    }
}


.hero-legend > div{
    background: #000;
}

.link-scroll{
    position: relative;
    text-transform: uppercase;
    display: block;
    padding: 8px 0 10px 80px;
    background-image: url(../images/arrow-down-hover.png);
    background-size: contain;
    background-repeat: no-repeat;
    font-size: 1.2em;
    height: 50px;
}

.link-scroll::before{
    content: '';
    background-image: url(../images/arrow-down.png);
    background-size: contain;
    background-repeat: no-repeat;
    width: 50px;
    height: 50px;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    transition: all 0.2s ease-out;
}

.link-scroll:hover::before{
    opacity: 0;
}

.link-cta{
    position: relative;
    text-transform: uppercase;
    display: block;
    padding: 8px 0 10px 80px;
    background-image: url(../images/arrow-diag-hover.png);
    background-size: contain;
    background-repeat: no-repeat;
    font-size: 1.2em;
    height: 50px;
}

.link-cta::before{
    content: '';
    background-image: url(../images/arrow-diag.png);
    background-size: contain;
    background-repeat: no-repeat;
    width: 50px;
    height: 50px;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    transition: all 0.2s ease-out;
}

.link-cta:hover::before{
    opacity: 0;
}


.hero-content{
    max-width: 1200px;
    margin: 0 auto;
    padding-top: 200px;
    padding-bottom: 50px;
}


@media only screen and (max-width: 760px){
    .hero-content{
        padding-top: 0px;
    }
}