.roadmap{
    
}

.roadmap-img-container{
    position: relative;
}

.roadmap-img{
    backdrop-filter: blur(5px);
    box-shadow: 0px 0px 5px rgba(111, 111, 111, 0.4);
    border-radius: 20px;
    z-index: 2;
    position: relative;   
    padding: 2em;
}

.roadmap-circle-1{
    position: absolute;
    width: 200px;
    height: 200px;
    right: -100px;
    bottom: -100px;
    background-image: url('../images/ball1.jpg');
    background-size: cover;
    width: 200px;
    height: 200px;
    z-index: 1;
}

.roadmap-circle-2{
    position: absolute;
    width: 200px;
    height: 200px;
    left: -60px;
    top: -60px;
    background-image: url('../images/ball1.jpg');
    background-size: cover;
    width: 130px;
    height: 130px;
    z-index: 1;
}