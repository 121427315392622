@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;700&display=swap');

@font-face {
    font-family: 'ff-meta-web-pro';
    src: local('ff-meta-web-pro'), url(./fonts/ff-meta-web-pro-normal.woff) format('woff');
}

a{
    transition: all 0.2s ease-out;
}

a:hover{
    color: #5353fe;
}

html{
    scroll-behavior: smooth;
    scroll-padding-top: 10em; 
    overflow-x: hidden;
}

body{
    font-family: 'DM Sans', sans-serif;
    background-color: #000;
    color: #fff;
    overflow-x: hidden;
}

.font-title{
    font-family: Montserrat;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 1.8em;
    font-style: italic;
}

.col-1{
    max-width: 1200px;
    margin: 0 auto;
    padding-left: 20px;
    padding-right: 20px;
}

.col-2{
    max-width: 800px;
    margin: 0 auto;
}

.gradient-top{
    background-image: url(./images/gradient-top.png);
    background-position: top left;
    background-repeat: no-repeat;
    background-size: 120px 2px;
    padding-top: 20px;
}

.arrow-title{
    display: block;
    width: 0.7em;
}

.arrow-title.left{
    transform: rotate(90deg);
}

.link-arrow{
    display: inline-block;
    width: 280px;
    text-align: center;
    text-transform: uppercase;
    padding: 10px 40px 10px 20px;
    color: #fff;
    border: 1px solid #fff;
    border-radius: 200px;
    position: relative;
    transition: all 0.5s cubic-bezier(0.77, 0, 0.175, 1);
}

@media only screen and (max-width: 760px){
    .link-arrow{
        width: 229px;
    }    
}



.link-arrow:hover{
    padding: 10px 20px 10px 40px;
    border: 1px solid #9c79ff;
    color: #856cff;
}

.link-arrow::after{
    content: '';
    position: absolute;
    top: 4px;
    right: 4px;
    border-radius: 100%;
    background-color: #fff;
    padding: 18px;
    transition: all 0.5s cubic-bezier(0.77, 0, 0.175, 1);
    background-image: url(./images/arrow-left.svg);
    background-position: center center;
    background-repeat: no-repeat;
    transform: none;
}

.link-arrow:hover::after{
    right: calc(100% - 40px);
    transform: rotate(180deg);
    background-color: #856cff;
}



.typer-main{
    background-color: #f3ec78;
    background-image: linear-gradient(90deg, #5353fe, #b9aefd);
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-text-fill-color: transparent;
    padding-right: 0.2em;
    margin-right: -0.2em;

}

.typer-cursor{
    color: #b9aefd !important;
}

.text-arrow{
    display: inline-block;
    width: 1em;
    margin-right: 5px;
}

.styled-table {
    border-collapse: collapse;
    font-size: 0.9em;
    font-family: sans-serif;
    margin: 0 auto;
    font-family: 'DM Sans', sans-serif;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.styled-table tr th {
    background-image: linear-gradient(45deg, #08081a, #0f1919);
    color: #ffffff;
    text-align: left;
    font-size: 1.2em;
    font-style: italic;
    font-weight: normal;
}

.styled-table th,
.styled-table td {
    padding: 12px 15px;
    border: 1px solid #151515;
}

.tokenomics-table{
    max-width: 400px;
    min-width: 270px;
}

@media only screen and (max-width: 760px){
    .tokenomics-table{
        width: 90%;
    }   
}

.button{
    display: inline-block;
    padding: 10px 20px;
    border-radius: 80px;
    background-color: #fff;
    color: #000;
    font-size: 15px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
    cursor: pointer;
    transition: all .3s ease;
}

.button.big{
    padding: 15px 23px;
    font-size: 1.2em;
    font-family: Montserrat;
}

.button:hover{
    background-color: #6dc7e4;
}

.button.alert{
    background: #e7b045;
}

.button.dark{
    background-color: #444;
    color: #fff;
}

.button.dark:hover{
    background-color: #666;
}

.mid-screen-msg{
    text-align: center;
    padding-top: 200px;
}

.pill{
    background-color: #444;
    font-size: 0.6em;
    text-transform: uppercase;
    padding: 4px;
    border-radius: 5px;
    letter-spacing: 1px;
    opacity: 0.3;
    vertical-align: middle;
}

.parallax-container{
    position: absolute;
    top: 50%;
    left: 180px;
    transform: translateY(-50%);
}

.pill.active{
    opacity: 1;
}

.hover-opacity{
    opacity: 1;
    transition: all .3s ease;
}

.hover-opacity:hover{
    opacity: 0.5;
}

.pill.ghost{
    background: none;
    border: 1px solid #444;
}

.card{
    padding: 30px;
    border-radius: 20px;
    margin: 10px;
}

.card h3,
.modal-content h3{
    font-size: 1.5em;
    margin-bottom: 25px;
}

.card h4{
    font-size: 1.2em;
    font-weight: bold;
    margin-bottom: 0.8em;
}

h5{
    
}

.sep-top{
    border-top: 1px solid #444444;
}

.dashboard{
    font-size: 0.9em;
    font-family: 'Roboto', sans-serif;
}

.dashboard textarea,
.modal-content textarea{
    background-color: #444;
    border: 1px solid #444;
    border-radius: 10px;
    padding: 5px 10px;
    outline: none !important;
}

.dashboard textarea:focus,
.modal-content textarea:focus{
    border: 1px solid #8956ff;
}


.dashboard select,
.modal-content select{
    background-color: #444;
    border-radius: 10px;
    padding: 10px 5px;
}

.dashboard input[type=text],
.modal-content input[type=text]{
    background-color: #555;
    border: 1px solid #555;
    border-radius: 10px;
    min-height: 38px;
    padding: 5px 10px;
    outline: none !important;
}

.dashboard input[type=text]:focus,
.modal-content input[type=text]:focus{
    border: 1px solid #8956ff;
}

.artists-list li{
    margin-bottom: 5px;
    padding-bottom: 5px;
    padding-top: 5px;
    border-bottom: 1px solid #444;
}

.dashboard .button,
.modal-content .button{
    padding: 10px 15px 8px 15px;
    font-size: 0.8em;
    border-radius: 10px;
}

.button.small{
    padding: 5px 10px;
    font-size: 0.7em;
    border-radius: 7px;
}

.button.ghost{
    padding: 5px 10px;
    font-size: 0.7em;
    background-color: transparent !important;
    color: #fff;
}

.button.ghost:hover{
    background-color: #555 !important;
}

.button.disabled{
    opacity: 0.2;
    pointer-events: none;
}

.pagination .button{
    font-size: 1.3em !important;
}

iframe{
    display: none !important;
}

.header{
    padding: 20px;
    position: fixed;
    left: 0;
    right: 0;
    z-index: 9;
}

.header-content{
    max-width: 2000px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    justify-items: center;
}

.header .logo{
    padding-left: 0.5em;
}

.header .logo img{
    max-width: 140px;
}

.header-button{
    border: 2px solid #6362fe;
    border-radius: 10px;
    padding: 8px 13px;
    margin-right: 30px;
    font-size: 0.9em;
    background-color: rgba(0, 0, 0, 0.7);
}

.header-button.small{
    font-size: 0.8em;
    padding: 3px 10px;
}

.header-button:hover{
    color: #04cfee;
    border-color: #04cfee;
}

.header .social{
    font-size: 1.5em;
    padding-right: 0.5em;
    display: flex;
    align-items: center;
}

.header .social a{
    display: flex;
    align-items: center;
    padding: 0 10px;
}

.header img{
    border-radius: 10px;
    width: 100%;
}   

h2{
    font-family: Montserrat;
    font-weight: bold !important;
    text-transform: uppercase;
    font-style: italic;
    line-height: 1.2em;
}

h2.title{
    font-size: clamp(2em, 4vw, 3em);
}

h2.title.smaller{
    font-size: clamp(1.5em, 3vw, 2em);
}

.hero-content{
    
}

.hero-content h4{
    font-size: clamp(1.4em, 2.8vw, 1.8em);
    font-style: italic;
}

.mint-qty{
    background: transparent;
    border: 1px solid #fff;
    outline: none;
    padding: 6px 12px;
    border-radius: 10px;
    width: 60px;
    margin: 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.button-circular{
    background: #fff;
    display: inline-block;
    color: #000;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    line-height: 50px;
    font-size: 1.2em;
    opacity: 0.7;
    outline: none;
    transition: all 0.2s ease-in-out;
}

.button-circular:hover{
    opacity: 1;
}

.artist-thumb{
    border-radius: 5px;    
}

.ReactModal__Overlay {
    opacity: 0;
    transition: opacity 200ms ease-in-out;
    z-index: 10;
}

.ReactModal__Overlay--after-open{
    opacity: 1;
}

.ReactModal__Overlay--before-close{
    opacity: 0;
}

.ReactModal__Content{
    padding: 0 !important;
    inset: 0px !important;
}

.modal-content{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 500px;
    background: #1e1f23;
    padding: 30px;
    border-radius: 20px;
    z-index: 10;
}

.modal-close{
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 2em;
}

.artist-social{
    font-size: 1.5em;
}

.team-member img{
    max-width: 350px;
}

@media only screen and (max-width: 760px){
    .team-member img{
        width: 250px;
        margin: 0 auto;
    } 
}

