.faq-row{
    border-bottom: 1px solid rgba(255, 255, 255, 0.4) !important;
}

.faq-row .row-content-text{
    padding-bottom: 20px !important;
}

.row-content-text a{
    color: #fff;
    transition: all 0.1s ease-out;
}

.row-content-text a:hover{
    color: rgba(255, 255, 255, 0.8);
}

.row-title{
    opacity: 0.7;
    transition: all 0.2s ease-out;
}

.row-title:hover{
    opacity: 1;
}

.faq-table{
    border-collapse: collapse;
}

.faq-table td{
    border: 1px solid rgba(255, 255, 255, .2);
    padding: 10px;
}