.dates{
    display: flex;
    flex-direction: column;
}

.date-entry:nth-child(1){
    align-self:flex-start;
}

.date-entry:nth-child(2){
    align-self: center;
}

.date-entry:nth-child(3){
    align-self: flex-end;
}

@media only screen and (max-width: 760px){

    .date-entry:nth-child(2){
        align-self: flex-start;
    }

    .date-entry:nth-child(3){
        align-self: flex-start;
    }   
}

.date-entry{
    display: flex;
    align-items: center;
    margin: 30px 0;
}

.date-entry .date{
    text-align: center;
    padding: 20px 30px;
    margin-right: 30px;
    font-weight: bold;
    font-family: Montserrat, sans-serif;
    text-transform: uppercase;
    border: 1px solid rgba(55, 55, 55, 0.4);
    padding: 20px;
    border-radius: 20px;
}

.date-entry .day{
    font-size: 3.7em;
    line-height: 0.8em;
}

.date-entry .month{
    font-size:1.7em;
}

.date-entry .date-details{
    font-family: Montserrat, sans-serif;
    text-transform: uppercase;
    font-style: italic;
    font-size: 1.5em;
}

@media only screen and (max-width: 760px){
    .date-entry .date-details{
        font-size: 1em;
    }
}